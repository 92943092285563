export default {
  development: {
    MS_URL: "https://notificationcontrol-dev.qa-enphaseenergy.com",
  },
  qa4: {
    MS_URL: "https://notificationcontrol-dev.qa-enphaseenergy.com",
  },
  qa2: {
    MS_URL: "https://notificationcontrol-stg.qa-enphaseenergy.com",
  },
  preprod: {
    MS_URL: "https://notificationcontrol-preprod.enphaseenergy.com",
  },
  prod: {
    MS_URL: "https://notificationcontrol.enphaseenergy.com",
  },
};
