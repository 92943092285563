import React, { useEffect, useState } from "react";
import "./styles.scss";
import CalendarIcon from "../../assets/calendarIcon.svg";

const DatePicker = (props) => {
  let [rootValue, setRoot] = useState("");

  function showCalenderUI(e) {
    if (e.target.className == "datepicker") {
      let calernder = e.target.parentNode.nextElementSibling;

      let daysDiv = calernder.lastChild;

      let root = e.target;

      //let root = elem;
      //root.setAttribute('autocomplete', 'off');
      //let calernder = document.createElement('div');
      //calernder.classList.add('calender');
      // document.body.appendChild(calernder);

      let daysList = [];
      let isCalenderVisible = false;
      let mouseX = 0;
      let mouseY = 0;
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      let markedDay = 0;
      let markedMonth = 0;
      let markedYear = 0;

      // root.nextElementSibling.addEventListener('click'  , e => {
      //     showCalender();
      // });

      showCalender();

      document.addEventListener("click", (e) => {
        if (isCalenderVisible) {
          mouseX = e.clientX;
          mouseY = e.clientY;
          let rect = calernder.getBoundingClientRect();
          if (mouseX > rect.left && mouseX < rect.right)
            if (mouseY > rect.top && mouseY < rect.bottom) return;
          hideCalender();
        }
      });

      daysDiv.addEventListener("click", (e) => {
        // let bRect = daysDiv.getBoundingClientRect();
        // let nRows = daysList.length / 7;
        // let dHeight = bRect.height/ nRows;
        // let dWidth = bRect.width / 7;
        // let yIndex = Math.floor( (e.clientY - bRect.top)/dHeight);
        // let xIndex = Math.floor( (e.clientX - bRect.left)/dWidth);
        // let day = daysList[yIndex * 7 + xIndex];
        let day = e.target.innerText;
        // console.log("daysList",daysList);
        // console.log("nRows",nRows);
        // console.log("dHeight",dHeight);
        // console.log("dWidth",dWidth);
        // console.log("day",day);
        if (Number(day) > 0 && Number(day) < 32) {
          markedDay = Number(day);
          markedMonth = month;
          markedYear = year;
          let d = String(day);
          let m = String(month + 1);
          let y = String(year);
          if (d.length == 1) d = "0" + d;
          if (m.length == 1) m = "0" + m;

          root.value = `${m}/${d}/${y}`;
          setRoot(`${m}/${d}/${y}`);
          props.onChange(`${m}/${d}/${y}`, `${m}`, `${d}`, `${y}`);

          //document.getElementById('datepicker').value=`${m}/${d}/${y}`;
          hideCalender();
        }
      });

      let capBtns = calernder.childNodes[0];
      capBtns = capBtns.getElementsByTagName("a");
      capBtns[0].addEventListener("click", (e) => {
        year--;
        renderCalender();
      });
      capBtns[1].addEventListener("click", (e) => {
        month--;
        if (month < 0) {
          month = 11;
          year--;
        }
        renderCalender();
      });
      capBtns[2].addEventListener("click", (e) => {
        month++;
        if (month > 11) {
          month = 0;
          year++;
        }
        renderCalender();
      });
      capBtns[3].addEventListener("click", (e) => {
        year++;
        renderCalender();
      });

      function showCalender() {
        setTimeout(() => {
          isCalenderVisible = true;
        }, 200);
        calernder.style.display = "block";
        let { height, top, left } = root.getBoundingClientRect();
        let cRect = calernder.getBoundingClientRect();
        let y = top + window.pageYOffset;
        let x = left + window.pageXOffset;
        let offset = 4 + height;

        if (top + cRect.height + height > window.innerHeight) {
          offset = -4 - cRect.height;
        }
        calernder.style.top = y + offset + "px";
        calernder.style.left = x + "px";
        renderCalender();
      }

      function hideCalender() {
        isCalenderVisible = false;
        calernder.style.display = "none";
      }

      function renderCalender() {
        let cap = calernder.childNodes[0].childNodes[2];
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        cap.innerText = `${months[month]}, ${year}`;
        let today = new Date();
        let nDays = new Date(year, month + 1, 0).getDate();
        let startDay = new Date(year, month, 1).getDay();
        daysList = [];
        for (let i = 0; i < startDay - 1; i++) {
          daysList.push(0);
        }
        for (let i = 0; i <= nDays; i++) {
          daysList.push(i);
        }
        daysDiv.innerHTML = "";
        for (let d of daysList) {
          let b = document.createElement("a");

          if (
            month == today.getMonth() &&
            d == today.getDate() &&
            year == today.getFullYear()
          ) {
            b.style.backgroundColor = "#ddd";
          }
          if (markedDay > 0) {
            if (markedYear == year && markedMonth == month && d == markedDay) {
              b.style.backgroundColor = "#2979ff";
              b.style.color = "#fff";
            }
          }
          b.innerText = d === 0 ? "" : `${d}`;
          daysDiv.appendChild(b);
        }
      }
    }
  }

  return (
    <div>
      <div className={`datepickerDiv ${props.containerClassName}`}>
        <input
          autoComplete="off"
          onClick={showCalenderUI}
          type="text"
          id="datepicker"
          placeholder={props.placeholder}
          value={rootValue}
          className="datepicker"
        />

        <img id="dateIcon" onClick={showCalenderUI} src={CalendarIcon} />
      </div>
      <div style={{ display: "none" }} className="calender">
        <div class="sp-cal-title">
          <a>{"<<"}</a>
          <a>{"<"}</a>
          <span class="cal-caption"></span>
          <a>{">"}</a>
          <a>{">>"}</a>
        </div>
        <div class="sp-cal-label-days">
          <span>SUN</span>
          <span>MON</span>
          <span>TUE</span>
          <span>WED</span>
          <span>THU</span>
          <span>FRI</span>
          <span>SAT</span>
        </div>
        <div class="sp-cal-days"></div>
      </div>
    </div>
  );
};

export default DatePicker;
