import { useEffect, useState } from "react";
import "./styles.scss";
import tickIcon from "../../assets/Path.svg";

const Select = (props) => {
  let [Option, setOption] = useState(props.value);
  let [show, setShow] = useState(false);

  function checkOnlyOption(e) {
    setOption(e.target.value);
    e.target.parentNode.parentNode.style.display = "none";
    setShow(false);
  }

  useEffect(() => {
    setOption(props.value);
  }, [props.value]);

  //console.log(props.value);
  function toggle(e) {
    let checkboxes = e.currentTarget.nextSibling;
    if (!show) {
      checkboxes.style.display = "block";
      setShow(true);
    } else {
      checkboxes.style.display = "none";
      setShow(false);
    }

    let dropDown = e.currentTarget.nextSibling;
    let inputDropDown = e.currentTarget;
    let mouseX = 0;
    let mouseY = 0;
    document.addEventListener("click", (e) => {
      //   if (
      //     e.target.className.indexOf("toggleOptions") < 0 &&
      //     !(
      //       e.target.className.indexOf("selectBox") >= 0 ||
      //       e.target.className.indexOf("optionDiv") >= 0
      //     )
      //   ) {
      mouseX = e.clientX;
      mouseY = e.clientY;
      let rect = inputDropDown.getBoundingClientRect();

      if (mouseX > rect.left && mouseX < rect.right)
        if (mouseY > rect.top && mouseY < rect.bottom) return;

      dropDown.style.display = "none";
      setShow(false);
      //   }
    });
  }

  return (
    <div
      className={`multiselect ${props.containerClassName}`}
      style={{ ...props.containerStyles }}
    >
      {props.fieldName ? (
        <div className={"sectionHeader head"}>{props.fieldName}</div>
      ) : null}
      <div className="selectBox" onClick={toggle}>
        <div id={Option} key={Option} className="optionDiv">
          {props.options[Option || props.value]
            ? props.options[Option || props.value].slice(
                0,
                props.limitCharacter
              ) +
              (props.options[Option || props.value].length >
              props.limitCharacter
                ? "..."
                : "")
            : ""}
        </div>
        <div className={show ? "arrowSelect up" : "arrowSelect down"}></div>
      </div>

      <div
        style={{ ...props.containerStyles }}
        className={`toggleOptions ${props.className || ""}`}
      >
        {Object.keys(props.options).map((v) => (
          <label
            key={v}
            className={`makeCheck ${Option == v ? "defaultSelected" : ""}`}
            htmlFor={v}
          >
            <input
              name={v}
              onChange={props.onChange}
              onClick={checkOnlyOption}
              className="hideCheckBox"
              type="checkbox"
              value={v}
              id={v}
            />

            {props.options[v]}
            {Option == v ? (
              <img className="imageOfDropDown" src={tickIcon}></img>
            ) : null}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Select;
