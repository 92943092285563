const Input = (props) => {
  return (
    <div>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        type={props.type ? props.type : "text"}
        id={props.id}
        className={props.className}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        onClick={props.onClick}
        value={props.value}
      ></input>
    </div>
  );
};

export default Input;
